import React from 'react';
import { DateTime } from 'luxon';
import { SvgIcon, useMediaQuery, Grid, Popover, Typography, Button } from "@material-ui/core";
import { CheckCircleOutlineRounded } from "@material-ui/icons";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'core/contexts/localization';

import { useStyles } from './styles';
import { nationalityList } from 'core/constants';

import { TriperService } from 'core/api/services/triper';

import Footer from 'components/Footer';
import NavBar from "components/NavBar";
import TermsAndConditions from 'components/TermsAndConditions';
import AlertDialog from 'components/AlertDialog';

import { Form, Field } from 'react-final-form';
import { AutocompleteInput } from 'components/Input/AutocompleteMUI';
import TextInput from 'components/Input/Text';
import { DateInput } from 'components/Input/DateInput';
import Checkbox from "components/Checkbox";

const CheckinTemplate = ({ goBackUrl }) => {

    const { t: translate } = useTranslation();

    const classes = useStyles();

    // const search = window.location.search;
    // const params = new URLSearchParams(search);
    // const email = params.get('email');

    const history = useHistory();

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));

    // const { getUserEmail } = usePayment();
    // const { getCheckIn } = usePayment();

    // const renderAuthForm = () => {
    //     return (
    //         <React.Fragment>
    //             <h1 style={{ marginTop: "25px" }}>Online check-in</h1>
    //             <div className={classes.authFormDiv}>

    //             </div>
    //             {/* <button
    //                     className="triper-button bg-blue-300"
    //                     style={{ marginLeft: "15vw", marginTop: "3vh" }}
    //                     onClick={() => history.replace(`/${goBackUrl}`)}
    //                 >
    //                     {translate("utils.backToSite")}
    //                 </button> */}
    //             <div class="outer">
    //                 <div class="middle">
    //                     <div class="inner" style={{ overflowWrap: "anywhere" }}>
    //                         <div className={classes.container}
    //                             style={{ marginInline: "auto", borderColor: "#A6BA62", borderStyle: "solid", borderWidth: "0.5rem", paddingInline: "1rem", marginTop: "1.5rem", marginBottom: "1.5rem" }}
    //                         >
    //                             <div style={{ width: "13rem", height: "4.5rem", marginInline: "auto", marginBottom: "1.5rem", marginTop: "1rem" }}>
    //                                 <img src={logo} style={{ width: "inherit", height: "inherit" }} />
    //                             </div>
    //                             <h1 className={classes.text} style={{ marginBottom: "1rem" }}>
    //                                 <strong>
    //                                     {translate("templates.purchase.title")}
    //                                 </strong>
    //                             </h1>
    //                             <SvgIcon style={{ height: "4rem", width: "4rem", color: "#A6BA62", display: "flex", marginInline: "auto", marginBottom: "1rem" }}>
    //                                 <CheckCircleOutlineRounded />
    //                             </SvgIcon>
    //                             <div>
    //                                 <h1 className={classes.text} style={{ marginBottom: "3.5rem" }}>
    //                                     <strong>
    //                                         {`${translate("templates.purchase.beforeBodyEmail")} ${userEmail} ${translate("templates.purchase.afterBodyEmail")}`}
    //                                     </strong>
    //                                 </h1>
    //                             </div>
    //                             <h1 className={classes.text} style={{ marginBottom: "3.5rem" }}>
    //                                 <strong>
    //                                     {translate("templates.purchase.enjoy")}
    //                                 </strong>
    //                             </h1>
    //                             <hr
    //                                 style={{
    //                                     border: "none",
    //                                     borderTop: "0.15rem dashed #000",
    //                                     color: "#fff",
    //                                     width: "50%",
    //                                     marginBottom: "3.5rem"
    //                                 }}
    //                             />
    //                             <h1 className={classes.text} style={{ marginBottom: "1rem" }}>
    //                                 <strong>
    //                                     {translate("templates.purchase.beforeEmail")}
    //                                 </strong>
    //                             </h1>
    //                             <a href="mailto:hello@utriper.com" className={classes.text} style={{ marginBottom: "1rem" }}>
    //                                 <strong>
    //                                     hello@utriper.com
    //                                 </strong>
    //                             </a>
    //                             <h1 className={classes.text}>
    //                                 <strong>
    //                                     {translate("templates.purchase.afterEmail")}
    //                                 </strong>
    //                             </h1>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </React.Fragment>
    //     );
    // };

    // const classes = useStyles();
    const initAuth = { lastName: "true", bookingCode: "true" };
    const initialTriperFormErrors = {
        firstName: true,
        lastName: true,
        email: true,
        dob: true,
        personalId: true,
        country: true,
    };
    const [experiences, setExperiences] = React.useState([]);
    const [checkinAuth, setCheckinAuth] = React.useState(initAuth);
    const [authFormErrors, setAuthFormErrors] = React.useState(initAuth);
    const [checkinData, setCheckinData] = React.useState([]);
    const [triperFormErrors, setTriperFormErrors] = React.useState([initialTriperFormErrors]);
    const [isAuth, setAuth] = React.useState(false);
    const [canEdit, setCanEdit] = React.useState(false);
    const [sameTriper, setSameTriper] = React.useState(true);
    const [hasError, setHasError] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openModal, setOpenModal] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [agreeWithTermsAndConditions, setAgreeWithTermsAndConditions] = React.useState(false);
    // const [submitted, setSubmitted] = React.useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const validate = (value) => {
        return value;
    };

    const validateEmail = (email) => {
        if (!email) return false;
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const validateResponse = ({data, errors}) => {
        if(!data || !data[0]) { 
            if(errors.length !== 0) {
                errors.map( err => toast.error(err.msg, {
                    position: 'top-right',
                    theme: 'colored',
                  }))
            } else {
                toast.error("Ups, it seems to be a problem!", {
                    position: 'top-right',
                    theme: 'colored',
                })
            }
            throw "";
        }
        if(errors.length !== 0) {
            errors.map( err => toast.error(err.msg, {
                position: 'top-right',
                theme: 'colored',
              }))
        }
    };

    const canSubmit = (values, checkingFromPayment = false) => {

        let formHasErrors = false;
        if (Array.isArray(values)) {
            values.forEach(triperFormErr => {
                let err = Object.values(triperFormErr).some(
                    (errorValue) => errorValue
                )
                if (err) { formHasErrors = true; }
            });
            if (!agreeWithTermsAndConditions) { formHasErrors = true; }
        } else {
            formHasErrors = Object.values(values).some(
                (errorValue) => errorValue
            );
        }
        // if (!checkingFromPayment) {
        //     setSubmitted(true);
        // }
        if (formHasErrors) {
            console.log('FORM HAS ERRORS', formHasErrors);
            return false;
        }
        return true;
    };

    const onSubmitAuth = async (values) => {
        try {
            console.log("onSubmitAuth")
            const auxExperiences = experiences;
            const res = await TriperService.checkinAuth(checkinAuth);
            validateResponse(res);
            const {accessCode, status} = res.data[0];
            const {data, errors} = await TriperService.getBooking(accessCode);
            validateResponse({data, errors});
            data[0].accessCode = accessCode;
            if(status == 1) {
                const qty = parseInt(data[0].quantity);
                data[0].tripers = [];
                let auxTriperFormErrors = [];
                for (let i = 0; i < qty; i++) {
                    const triper = {
                        firstName: '',
                        lastName: '',
                        email: '',
                        dob: '',
                        nationality: '',
                        personalIdType: 0,
                        personalId: '',
                    }
                    const triperError = {
                        firstName: i !== 0,
                        lastName: i !== 0,
                        email: i !== 0,
                        nationality: true,
                        dob: true,
                        personalId: true,
                    }
                    if (i === 0) {
                        triper.firstName = data[0].firstName;
                        triper.lastName = data[0].lastName;
                        triper.email = data[0].email;
                    }
                    data[0].tripers.push(triper);
                    auxTriperFormErrors.push(triperError);
                }
                auxExperiences.push({
                    accessCode: accessCode,
                    name: data[0].name,
                    date: data[0].dateUTC,
                    timezone: data[0].timezone,
                    location: data[0].location,
                    quantity: data[0].quantity,
                    status: data[0].status,
                });
                setCheckinData(data[0]);
                setTriperFormErrors(auxTriperFormErrors);
                setCanEdit(true);
                setAuth(true);
            } else if (status == 2) {
                const qty = parseInt(data[0].quantity);
                data[0].tripers = [];
                let auxTriperFormErrors = [];
                for (let i = 0; i < qty; i++) {
                    const triper = {
                        firstName: data[i].firstName,
                        lastName: data[i].firstName,
                        email: data[i].firstName,
                        dob: data[i].firstName,
                        nationality: data[i].firstName,
                        personalIdType: 0,
                        personalId: data[i].firstName,
                    }
                    data[0].tripers.push(triper);
                }
                auxExperiences.push({
                    accessCode: accessCode,
                    name: data[0].name,
                    date: data[0].dateUTC,
                    timezone: data[0].timezone,
                    location: data[0].location,
                    quantity: data[0].quantity,
                    status: data[0].status,
                });
                setCheckinData(data[0]);
                setCanEdit(false);
                setAuth(true);
            }

            // Lets get if there is other bookings to the code
            // Start in one cause already did first
            for(let i = 1; i < res.data.length; i++) {
                const {accessCode, status} = res.data[i];
                const {data, errors} = await TriperService.getBooking(accessCode);
                auxExperiences.push({
                    accessCode: accessCode,
                    name: data[0].name,
                    date: data[0].dateUTC,
                    timezone: data[0].timezone,
                    location: data[0].location,
                    quantity: data[0].quantity,
                    status: data[0].status,
                });
            }
            setExperiences(auxExperiences);

        } catch (err) {
            console.log("ERROR onSubmitAuth")
            setCanEdit(false);
            setAuth(false);
        }
    };
    const onSubmitCheckin = async (values) => {
        try {
            console.log("onSubmitCheckin")
            for (let i = 0; i < experiences.length; i++) {
                const element = experiences[i];
                const {data, errors} = await TriperService.checkinTriper({accessCode: element.accessCode, tripers: checkinData.tripers});
                console.log("data", data);
                console.log("errors", errors);
                validateResponse({data, errors});
                if(parseInt(data[0].status) === 2) {
                    toast.success('Check-in successful', {
                        position: 'top-right',
                        theme: 'colored',
                    })
                }
            }
            setOpenDialog(false);
            setCanEdit(false);
        } catch (err) {
            console.log("ERROR onSubmitAuth")
        }
    };

    const renderHelp = () => {
        return (
            <Grid justifyContent='center'>
                <Grid item>
                    <Typography variant='h1' className={classes.helpText}>Help</Typography>
                </Grid>
                <Grid item>
                    <Typography variant='h5' className={classes.helpText}>Booking code</Typography>
                    <Typography className={classes.helpText}>
                        The booking code is a random combination of letters and numbers that are associated with a booking when it is made so that it can be easily retrieved.
                    </Typography>
                    <Typography variant='h5' className={classes.helpText}>Where can I find it?</Typography>
                    <Typography className={classes.helpText}>
                        image
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    const renderTermsAndConditions = () => {
        return (
            <>
                {translate('termsAndConditions.accept')}
                <a
                    onClick={(e) => {
                        console.log('CLICKED TERM AND CONDITIONS', e);
                        setOpenModal(true);
                        e.preventDefault();
                    }}>
                    {translate('termsAndConditions.link')}
                </a>
            </>
        );
    };

    const renderAuth = () => {
        return (
            <React.Fragment>
                <Form
                    onSubmit={onSubmitAuth}
                    // initialValues={{
                    //   ...contractData,
                    // }}
                    render={({ handleSubmit, form }) => (
                        <form id="form" onSubmit={handleSubmit}>
                            {/* <button
                    className="triper-button bg-blue-300"
                    style={{ marginLeft: "15vw", marginTop: "3vh" }}
                    onClick={() => history.replace(`/${goBackUrl}`)}
                    >
                    {translate("utils.backToSite")}
                </button> */}
                            {/* <div class="outer">
                <div class="middle">
                <div class="inner" style={{ overflowWrap: "anywhere" }}> */}
                            <div className={classes.container}>
                                <Grid container alignItems='center' spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <h1 style={{ marginTop: "25px" }}>Online check-in</h1>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Field
                                            name={"lastName"}
                                            label={"Last Name"}
                                            required
                                            setValidate={validate}
                                            errorMessage={(value) => translate('form.required')}
                                            setFormError={(value) =>
                                                setAuthFormErrors({ ...authFormErrors, lastName: value })
                                            }
                                            onFieldChange={(value) => {
                                                const auxCheckinAuth = {
                                                    ...checkinAuth,
                                                };
                                                auxCheckinAuth.lastName = value;
                                                setCheckinAuth(auxCheckinAuth);
                                            }}
                                            component={TextInput}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Field
                                            name={"bookingCode"}
                                            label={"Booking code"}
                                            required
                                            setValidate={validate}
                                            errorMessage={(value) => translate('form.required')}
                                            setFormError={(value) =>
                                                setAuthFormErrors({ ...authFormErrors, bookingCode: value })
                                            }
                                            onFieldChange={(value) => {
                                                console.log("value==", value)
                                                const auxCheckinAuth = {
                                                    ...checkinAuth,
                                                };
                                                auxCheckinAuth.bookingCode = value;
                                                setCheckinAuth(auxCheckinAuth);
                                            }}
                                            component={TextInput}
                                        />
                                    </Grid>
                                    <Grid container justifyContent='flex-end' item xs={12} sm={12}>
                                        <a aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
                                            What is the booking code?
                                        </a>
                                        <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                        >
                                            {renderHelp()}
                                        </Popover>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Button
                                            className={classes.checkinButton}
                                            onClick={(e) => {
                                                return handleSubmit(e);
                                            }}
                                            disabled={!canSubmit(authFormErrors)}
                                        >
                                            <Typography className={classes.checkinText}>Check in online</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                                {/* <div style={{ width: "13rem", height: "4.5rem", marginInline: "auto", marginBottom: "1.5rem", marginTop: "1rem" }}>
                                <img src={logo} style={{ width: "inherit", height: "inherit" }} />
                            </div> */}
                                {/* <h1 className={classes.text} style={{ marginBottom: "1rem" }}>
                                <strong>
                                    {translate("templates.purchase.title")}
                                </strong>
                            </h1> */}
                                {/* <SvgIcon style={{ height: "4rem", width: "4rem", color: "#A6BA62", display: "flex", marginInline: "auto", marginBottom: "1rem" }}>
                                <CheckCircleOutlineRounded />
                            </SvgIcon> */}
                                {/* <div>
                                <h1 className={classes.text} style={{ marginBottom: "3.5rem" }}>
                                    <strong>
                                        {`${translate("templates.purchase.beforeBodyEmail")} ${userEmail} ${translate("templates.purchase.afterBodyEmail")}`}
                                    </strong>
                                </h1>
                            </div> */}
                                {/* <h1 className={classes.text} style={{ marginBottom: "3.5rem" }}>
                                <strong>
                                    {translate("templates.purchase.enjoy")}
                                </strong>
                            </h1> */}
                                {/* <hr
                                style={{
                                    border: "none",
                                    borderTop: "0.15rem dashed #000",
                                    color: "#fff",
                                    width: "50%",
                                    marginBottom: "3.5rem"
                                }}
                            />
                            <h1 className={classes.text} style={{ marginBottom: "1rem" }}>
                                <strong>
                                    {translate("templates.purchase.beforeEmail")}
                                </strong>
                            </h1>
                            <a href="mailto:hello@utriper.com" className={classes.text} style={{ marginBottom: "1rem" }}>
                                <strong>
                                    hello@utriper.com
                                </strong>
                            </a>
                            <h1 className={classes.text}>
                                <strong>
                                    {translate("templates.purchase.afterEmail")}
                                </strong>
                            </h1> */}
                            </div>
                            {/* </div>
                </div>
            </div> */}
                        </form>
                    )} />
            </React.Fragment>
        );
    };

    const hideField = (index) => {
        if (sameTriper && index === 0) { return true; }
        return false;
    }

    const renderCheckin = () => {
        return (
            <React.Fragment>
                <Form
                    onSubmit={onSubmitCheckin}
                    initialValues={{
                        tripers: checkinData.tripers,
                    }}
                    render={({ handleSubmit, form }) => (
                        <form id="form" onSubmit={handleSubmit}>
                            <div className={classes.checkinFormDiv}>
                                <Grid container direction='column'>
                                    <Grid item><h1 style={{ marginTop: "25px", marginBottom: "35px" }}>Online check-in
                                    { canEdit ? "":
                                        (<Typography variant='h6' style={{color:"green"}}>COMPLETED</Typography>)
                                    }
                                    </h1>
                                    
                                    </Grid>
                                    <Grid container direction={'row'} justifyContent={isMobile ? "flex-start" : 'space-evenly'} alignItems={'flex-start'} style={{ padding: '20px', marginBottom: '20px', backgroundColor: "#F4F2F2" }}>
                                        <Grid item>
                                            <h1 style={{ marginTop: "25px" }}>Your booking code</h1>
                                            <Typography variant='h6'>{checkinData.code}</Typography>
                                        </Grid>
                                        {/* <Grid item lg={1}></Grid>
                                        <Grid item>
                                            <h1 style={{ marginTop: "25px" }}>Your booking  details</h1>
                                            <Typography>{checkinData.name}</Typography>
                                            <Typography>Date: {checkinData.date.substr(0,10)}</Typography>
                                            <Typography>Arrival Time: {checkinData.date.substr(11,5)}</Typography>
                                            <Typography>Location: {checkinData.location}</Typography>
                                            <Typography>Adults: {checkinData.quantity}</Typography>
                                        </Grid>
                                        <Grid item lg={1}></Grid> */}
                                        <Grid item>
                                            <h1 style={{ marginTop: "25px" }}>Your billing details</h1>
                                            <Typography>Name: {`${checkinData.firstName} ${checkinData.lastName}`}</Typography>
                                            <Typography>Email: {checkinData.email}</Typography>
                                            <Typography>Tel: {checkinData.mobilePhone}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container> 
                                        <Grid item xs>
                                            <h2 >Activies:</h2>
                                        </Grid> 
                                        {   
                                            experiences && experiences.length ? ( experiences.map( exp => (<>
                                                <Grid item container justifyContent="space-around" alignItems="center">
                                                    <Grid item>
                                                        <Typography variant='h4'>
                                                            {exp.name}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography>Date: {DateTime.fromISO(exp.date).setZone(exp.timezone).toFormat('dd LLL yyyy')}</Typography>
                                                        <Typography>Arrival Time: {DateTime.fromISO(exp.date).setZone(exp.timezone).toFormat('HH:mm')} {exp.timezone}</Typography>
                                                        <Typography>Location: {exp.location}</Typography>
                                                        <Typography>Adults: {exp.quantity}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </>)) ) : ''
                                        }
                                    </Grid>
                                    <Grid>
                                        <Grid container style={{ marginTop: "7vh" }}>
                                            <Grid hidden={!canEdit}  item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <h2 style={{ marginBottom: '5vh' }}>
                                                    <strong>
                                                        {translate('checkout.triper_details')}
                                                    </strong>
                                                </h2>
                                            </Grid>
                                            <Grid hidden={!canEdit} container item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                {
                                                    checkinData.tripers.map((triper, index) => {
                                                        return (
                                                            <>
                                                                <Typography className={classes.typographyWrapper}>
                                                                    {`Triper ${index + 1}`} {index !== 0 || !canEdit ? "" : (
                                                                        <Field
                                                                            style={{
                                                                                paddingTop: '2em',
                                                                            }}
                                                                            checked={sameTriper}
                                                                            onChange={(value) => {
                                                                                console.log("hereee ==== ", value)
                                                                                setSameTriper(value);
                                                                                if (value) { // We copy billing details to Triper 1 and set error to false

                                                                                    const auxCheckinData = {
                                                                                        ...checkinData,
                                                                                    };
                                                                                    const auxtriperData = checkinData.tripers[0];
                                                                                    auxtriperData.firstName = checkinData.firstName;
                                                                                    auxtriperData.lastName = checkinData.lastName;
                                                                                    auxtriperData.email = checkinData.email;
                                                                                    auxtriperData.nationality = checkinData.nationality;
                                                                                    auxCheckinData.tripers[0] = auxtriperData;

                                                                                    const auxTriperFormErrors = triperFormErrors;
                                                                                    auxTriperFormErrors[0].firstName = false;
                                                                                    auxTriperFormErrors[0].lastName = false;
                                                                                    auxTriperFormErrors[0].email = false;
                                                                                    auxTriperFormErrors[0].nationality = false;

                                                                                    setCheckinData(auxCheckinData);
                                                                                    setTriperFormErrors(auxTriperFormErrors)

                                                                                } else { // We clean triper 1 and set errors to true
                                                                                    const auxCheckinData = {
                                                                                        ...checkinData,
                                                                                    };
                                                                                    const auxtriperData = checkinData.tripers[0];
                                                                                    auxtriperData.firstName = "";
                                                                                    auxtriperData.lastName = "";
                                                                                    auxtriperData.email = "";
                                                                                    auxtriperData.nationality = "";
                                                                                    auxCheckinData.tripers[0] = auxtriperData;

                                                                                    const auxTriperFormErrors = triperFormErrors;
                                                                                    auxTriperFormErrors[0].firstName = true;
                                                                                    auxTriperFormErrors[0].lastName = true;
                                                                                    auxTriperFormErrors[0].email = true;
                                                                                    auxTriperFormErrors[0].nationality = true;

                                                                                    setCheckinData(auxCheckinData);
                                                                                    setTriperFormErrors(auxTriperFormErrors)
                                                                                }
                                                                            }}
                                                                            name="sameTriper"
                                                                            component={Checkbox}
                                                                            labelComponent={translate('checkout.same_person')}
                                                                        />
                                                                    )}
                                                                </Typography >
                                                                <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2} style={{ paddingLeft: "5vw", marginTop: '5px' }}>
                                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                                                                        <Field
                                                                            disabled={!canEdit || (sameTriper && index === 0)}
                                                                            name={`tripers[${index}].firstName`}
                                                                            label={translate('checkout.first_name')}
                                                                            required
                                                                            setValidate={validate}
                                                                            errorMessage={(value) => translate('form.required')}
                                                                            setFormError={(value) => {
                                                                                const auxTriperFormErrors = triperFormErrors;
                                                                                auxTriperFormErrors[index].firstName = value;
                                                                                setTriperFormErrors(auxTriperFormErrors)
                                                                            }}
                                                                            onFieldChange={(value) => {
                                                                                const auxCheckinData = {
                                                                                    ...checkinData,
                                                                                };
                                                                                const auxtriperData = checkinData.tripers[index];
                                                                                auxtriperData.firstName = value;
                                                                                auxCheckinData.tripers[index] = auxtriperData;
                                                                                setCheckinData(auxCheckinData);
                                                                            }}
                                                                            component={TextInput}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                                        <Field
                                                                            disabled={!canEdit || (sameTriper && index === 0)}
                                                                            name={`tripers[${index}].lastName`}
                                                                            label={translate('checkout.last_name')}
                                                                            required
                                                                            setValidate={validate}
                                                                            errorMessage={(value) => translate('form.required')}
                                                                            setFormError={(value) => {
                                                                                const auxTriperFormErrors = triperFormErrors;
                                                                                auxTriperFormErrors[index].lastName = value;
                                                                                setTriperFormErrors(auxTriperFormErrors)
                                                                            }}
                                                                            onFieldChange={(value) => {
                                                                                const auxCheckinData = {
                                                                                    ...checkinData,
                                                                                };
                                                                                const auxtriperData = checkinData.tripers[index];
                                                                                auxtriperData.lastName = value;
                                                                                auxCheckinData.tripers[index] = auxtriperData;
                                                                                setCheckinData(auxCheckinData);
                                                                            }}
                                                                            component={TextInput}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2} style={{ paddingLeft: "5vw" }}>
                                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                                                                        <Field
                                                                        className={classes.fieldEmail}
                                                                            disabled={!canEdit || (sameTriper && index === 0)}
                                                                            name={`tripers[${index}].email`}
                                                                            label="Email"
                                                                            required
                                                                            setValidate={validateEmail}
                                                                            errorMessage={(value) => {
                                                                                if (!value) return translate('form.required');
                                                                                if (value.length > 0)
                                                                                    return translate('form.invalid.email');
                                                                            }}
                                                                            setFormError={(value) => {
                                                                                const auxTriperFormErrors = triperFormErrors;
                                                                                auxTriperFormErrors[index].email = value;
                                                                                setTriperFormErrors(auxTriperFormErrors)
                                                                            }}
                                                                            onFieldChange={(value) => {
                                                                                const auxCheckinData = {
                                                                                    ...checkinData,
                                                                                };
                                                                                const auxtriperData = checkinData.tripers[index];
                                                                                auxtriperData.email = value;
                                                                                auxCheckinData.tripers[index] = auxtriperData;
                                                                                setCheckinData(auxCheckinData);
                                                                            }}
                                                                            component={TextInput}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                                                                        <Field
                                                                            disabled={!canEdit}
                                                                            name={`tripers[${index}].dob`}
                                                                            label={translate('checkout.dob') + ' *'}
                                                                            required
                                                                            onChange={(event) => {
                                                                                const auxCheckinData = {
                                                                                    ...checkinData,
                                                                                };
                                                                                const auxtriperData = checkinData.tripers[index];
                                                                                const auxTriperFormErrors = triperFormErrors;
                                                                                auxTriperFormErrors[index].dob = true;
                                                                                if (Date.parse(event)) {
                                                                                    auxtriperData.dob = event.toISOString().substr(0, 10);
                                                                                    auxCheckinData.tripers[index] = auxtriperData;
                                                                                    setCheckinData(auxCheckinData);
                                                                                    auxTriperFormErrors[index].dob = false;
                                                                                }
                                                                                setTriperFormErrors(auxTriperFormErrors)
                                                                            }}
                                                                            component={DateInput}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2} style={{ paddingLeft: "5vw", marginBottom: '16px' }}>
                                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                                                                        <Field
                                                                            disabled={!canEdit}
                                                                            name={`tripers[${index}].nationality`}
                                                                            label={
                                                                                translate('checkout.country') + ' *'
                                                                            }
                                                                            options={nationalityList}
                                                                            getOptionLabels={(option) => option.en_short_name}
                                                                            required
                                                                            setValidate={validate}
                                                                            errorMessage={(value) => translate('form.required')}
                                                                            setFormError={(value) => {
                                                                                const auxTriperFormErrors = triperFormErrors;
                                                                                auxTriperFormErrors[index].nationality = value;
                                                                                setTriperFormErrors(auxTriperFormErrors)
                                                                            }}
                                                                            onFieldChange={(event) => {
                                                                                const auxCheckinData = {
                                                                                    ...checkinData,
                                                                                };
                                                                                const auxtriperData = checkinData.tripers[index];
                                                                                auxtriperData.nationality = event.target.textContent;
                                                                                auxCheckinData.tripers[index] = auxtriperData;
                                                                                setCheckinData(auxCheckinData);
                                                                            }}
                                                                            component={AutocompleteInput}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                                                                        <Field
                                                                        className={classes.fieldPassport}
                                                                            disabled={!canEdit}
                                                                            name={`tripers[${index}].personalid`}
                                                                            label={translate('checkout.passport')}
                                                                            required
                                                                            setValidate={validate}
                                                                            errorMessage={(value) => translate('form.required')}
                                                                            setFormError={(value) => {
                                                                                const auxTriperFormErrors = triperFormErrors;
                                                                                auxTriperFormErrors[index].personalId = value;
                                                                                setTriperFormErrors(auxTriperFormErrors)
                                                                            }}
                                                                            onFieldChange={(value) => {
                                                                                const auxCheckinData = {
                                                                                    ...checkinData,
                                                                                };
                                                                                const auxtriperData = checkinData.tripers[index];
                                                                                auxtriperData.personalId = value;
                                                                                auxCheckinData.tripers[index] = auxtriperData;
                                                                                setCheckinData(auxCheckinData);
                                                                            }}
                                                                            component={TextInput}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        );
                                                    })
                                                }
                                            </Grid>
                                            <Grid hidden={!canEdit} container direction='column' alignItems='flex-end' item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Grid item>
                                                    <Field
                                                        style={{
                                                            paddingTop: '2em',
                                                        }}
                                                        // hasError={submitted && !agreeWithTermsAndConditions}
                                                        disabled={!canEdit}
                                                        hasError={!agreeWithTermsAndConditions}
                                                        errorMessage={translate('form.required')}
                                                        checked={agreeWithTermsAndConditions}
                                                        onChange={(value) => {
                                                            console.log("hereee ==== ", value)
                                                            setAgreeWithTermsAndConditions(value);
                                                        }}
                                                        name="termsAndConditions"
                                                        component={Checkbox}
                                                        labelComponent={renderTermsAndConditions()}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className={classes.checkinConfirmationButton}
                                                        variant="contained" size="large" color="secondary"
                                                        onClick={(e) => {
                                                            // return handleSubmit(e);
                                                            setOpenDialog(true);
                                                        }}
                                                        disabled={!canSubmit(triperFormErrors)}
                                                    >
                                                        <Typography className={classes.checkinText}>Submit</Typography>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            <Grid hidden={canEdit} container direction='column' alignItems='center' item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Typography>The check-in for this booking was completed already.
                                                        If any assistance is needed please don hesitate to reach us at <a href="mailto: hello@utriper.com">hello@utriper.com</a></Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    )} />
            </React.Fragment>
        );
    };

    const renderBody = () => {
        try {
            // const userEmail = getUserEmail();
            const userEmail = "getUserEmail();"

            return (
                <React.Fragment>
                    {isAuth === true ? renderCheckin() : renderAuth()}
                </React.Fragment>
            );
        } catch (err) {
            return history.replace("/error");
        }
    };

    return (
        <React.Fragment>
            {/* {renderBody()} */}
            <NavBar outBarComponent={renderBody} />
            <Footer />
            <TermsAndConditions
                open={openModal}
                onClose={() => setOpenModal(false)}
                onClick={() => {
                    setAgreeWithTermsAndConditions(!agreeWithTermsAndConditions);
                    setOpenModal(false);
                }}
            />
            <AlertDialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                onSubmit={onSubmitCheckin}
                title={`Please confirm`}
                contentText={`Once submited the check in you cannot modify the data.
                This data might be used for turist insurance purposes`}
            />
        </React.Fragment>
    );
}

export default CheckinTemplate;